import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import Breadcrumbs from "../breadcrumbs";

const BugsForm = () => {
  const data = useStaticQuery(
    graphql`
      query BugPackTopics {
        allMarkdownRemark(sort: { fields: [frontmatter___title], order: ASC }, filter: { frontmatter: { categoryid: { eq: "packs" } } }) {
          edges {
            node {
              frontmatter {
                title
                type
              }
            }
          }
        }
      }
    `
  );

  const { allMarkdownRemark } = data;
  return (
    <section>
      <Breadcrumbs>
        <span>Bug Report</span>
      </Breadcrumbs>
      <form name="Bug Report" accept-charset="utf-8" method="POST" action="/bugs?submitted=true" data-netlify="true">
        <fieldset>
          <label for="title">Title</label>
          <input type="text" name="Title" id="title" placeholder="Enter a title for your report" required></input>
          <label for="affected-pack">Topic</label>
          <select name="Topic" id="affected-pack" required>
            <option value="Select" selected disabled>
              Select a topic
            </option>
            <option value="Website">Website</option>
            {allMarkdownRemark.edges.map(({ node }) => (
              <option value={node.frontmatter.title}>{node.frontmatter.title}</option>
            ))}
          </select>
          <label for="details">Details</label>
          <textarea rows="5" name="Details" id="details" placeholder="Enter a detailed description of the issue" required></textarea>
          <label for="steps-to-replicate">Steps to Replicate</label>
          <textarea rows="5" name="Steps To Replicate" id="steps-to-replicate" placeholder="How do I make the bug occur?" required></textarea>
          <input type="hidden" name="form-name" value="Bug Report" />
        </fieldset>
        <input className="button button--submit-form button--bugs" type="submit" value="Report Bug"></input>
      </form>
      <h5>Form submissions are only viewed by QuazChick team members.</h5>
    </section>
  );
};

export default BugsForm;
